import { IApplicationMessage } from '@norstella/nxp-sso-web/services/Interfaces/IApplicationMessage'
import { SignupError } from '@norstella/nxp-sso-core/errors/sso-api/SignupError'
import { UpdateProfileError } from '@norstella/nxp-sso-core/errors/sso-api/UpdateProfileError'
import { ApplicationError } from '@norstella/nxp-sso-core/errors/ApplicationError'
import { AxiosError } from 'axios'
import { EmailAlreadyVerifiedError, SchemaValidationError } from '@norstella/nxp-sso-core/errors'

export type MessageFactory = (error: Error) => IApplicationMessage | Error
export type ErrorMessageHandler = [name: string, factory: MessageFactory]

const createApplicationMessage = (error: ApplicationError<never>): IApplicationMessage => {
  return {
    header: error.displayName,
    description: error.message,
    severity: 'error',
  }
}

const createAxiosErrorApplicationMessage = (error: AxiosError): IApplicationMessage => {
  return {
    header: error.name,
    description: error.message,
    severity: 'error',
  }
}

export const ErrorMessageHandlers: ErrorMessageHandler[] = [
  ["SignupError", (error: SignupError<never>) => createApplicationMessage(error)],
  ["UpdateProfileError", (error: UpdateProfileError<never>) => createApplicationMessage(error)],
  ["SchemaValidationError", (error: SchemaValidationError<never>) => createApplicationMessage(error)],
  ["EmailAlreadyVerifiedError", (error: EmailAlreadyVerifiedError<never>) => createApplicationMessage(error)],
  ["AxiosError", (error: AxiosError) => createAxiosErrorApplicationMessage(error)],
] as ErrorMessageHandler[]
